import utils from "../common/util";

const cmpLinkButton = {
    component: null,

    init: function (element)
    {
        utils.log('cmpLinkButton initiated : #' + element.id, 'init');

        const _ = this;
        _.component = element;
        _.href = _.component.getAttribute('data-href');

        _.onClickEvent(element);
    },

    onClickEvent: function (element)
    {
        const _ = this;

        element.addEventListener('click', () =>
        {
            window.location.href = _.href;
        });
    }
}

export default cmpLinkButton;
