import utils from "../common/util";

const cmpRightPanel = {
    component: null,

    init: function (element)
    {
        utils.log('cmpRightPanel initiated : #' + element.id, 'init');

        const _ = this;
        _.component = element;
        _.closeBtn = _.component.querySelector('.close-btn');
        _.triggers = document.getElementsByClassName('right-panel-trigger');

        _.onClickEvents();
    },

    onClickEvents: function ()
    {
        const _ = this;

        for (let selector of _.triggers) {
            selector.addEventListener('click', () =>
            {
                _.component.classList.add('open');
            });
        }

        _.closeBtn.addEventListener('click', () =>
        {
            _.component.classList.remove('open');
        })
    }
}

export default cmpRightPanel;
