const utils = {
    /**
     * Checks if we are in debugMode, and if so, writes a message to the console
     * @param msg
     * @param type
     */
    log: function (msg, type = 'log')
    {
        switch (type) {
            case 'init':
                if (this.getDebugMode()) {
                    console.info('%c%s', 'color: pink;', msg);
                }
                break;
            case 'info':
                console.info('%c%s', 'color: cyan;', msg);
                break;
            case 'debug':
                console.debug('%c%s', 'color: grey;', msg);
                break;
            case 'warn':
                console.warn('%c%s', 'color: orange;', msg);
                break;
            case 'error':
                console.error('%c%s', 'color: red;', msg);
                break;
            default:
                console.log('%c%s', 'color: white;', msg);
                break;
        }
    },

    getAppUrl: function ()
    {
        return document.querySelector("meta[name='app-url']").getAttribute("content");
    },

    getCsrfToken: function ()
    {
        return document.querySelector("meta[name='csrf-token']").getAttribute("content");
    },

    /**
     * checks to see if the .env file APP_DEBUG property is true. i.e. we are in debug mode
     */
    getDebugMode: function ()
    {
        return document.querySelector("meta[name='debug-mode']").getAttribute("content") || false;
    }
}

export default utils;
