import utils from "../common/util";

const cmpDragNDrop = {
    component: null,

    init: function (element)
    {
        utils.log('cmpDragNDrop initiated : #' + element.id, 'init');

        const _ = this;
        _.component = element;
        _.draggables = _.component.getElementsByClassName('draggable');
        _.dropzones = document.getElementsByClassName('dropzone');

        _.setListeners();
    },

    setListeners: function ()
    {
        const _ = this;

        for (let draggable of _.draggables) {
            draggable.addEventListener('dragstart', _.startDrag);
            draggable.addEventListener('dragend', _.endDrag);
        }

        for (let dropzone of _.dropzones) {
            dropzone.addEventListener('dragenter', _.dragEnter)
            dropzone.addEventListener('dragover', _.dragOver);
            dropzone.addEventListener('dragleave', _.dragLeave);
            dropzone.addEventListener('drop', _.drop);
        }
    },

    /**
     * Actions to perform when dragging of an item begins
     * @param item
     */
    startDrag: function (item)
    {
        let container = item.target;

        item.dataTransfer.setData('text/plain', container.id);
        container.classList.add('faded');
    },

    /**
     * Actions to perform when dragging of an item ends
     * @param item
     */
    endDrag: function (item)
    {
        let container = item.target;
        container.classList.remove('faded');
    },

    /**
     * Events for when the dragged item first enters a dropzone
     * @param e
     */
    dragEnter: function (e)
    {
        e.preventDefault();
        // e.stopPropagation();

        e.currentTarget.classList.add('drag-over');
    },

    /**
     * Events that run continuously while a dragged item is hovered over a dropzone
     * @param e
     */
    dragOver: function (e)
    {
        e.preventDefault();
    },

    /**
     * Events that are triggered when a dragged item leaves a dropzone
     * @param e
     */
    dragLeave: function (e)
    {
        e.preventDefault();
        e.stopPropagation();

        if(e.currentTarget !== e.relatedTarget.closest('tr'))
        {
            e.currentTarget.classList.remove('drag-over');
        }
    },

    /**
     * Events that are triggered after a dragged item has been dropped on a dropzone
     * @param e
     */
    drop: function (e)
    {
        // The row that the draggable item has been dropped onto
        const row = e.target.closest('tr');
        const service_id = row.getAttribute('data-service-id');
        const pageYear = row.getAttribute('data-page-year');

        // get the draggable element currently being dropped from the flyout panel
        const id = e.dataTransfer.getData('text/plain');
        const draggable = document.getElementById(id); // The item being dragged
        const transactionId = draggable.getAttribute('data-transaction-id');
        const itemYear = draggable.getAttribute('data-year');
        const itemMonth = draggable.getAttribute('data-month');
        const amountLabel = document.getElementById(id).querySelector('.amount').innerText;
        const amountValue = draggable.getAttribute('data-amount');

        fetch(utils.getAppUrl() + '/transactions/' + transactionId, {
            method: 'PATCH',
            headers: { 'X-CSRF-TOKEN': utils.getCsrfToken(),'Accept': 'application/json', 'Content-Type': 'application/json', },
            body: JSON.stringify({"service_id": service_id }),
        })
            .then(response => response.json())
            .then((data) => {
                if(data.status === "success")
                {
                    // remove styling from the dropzone row
                    (e.target).closest('tr').classList.remove('drag-over');

                    // remove the dragged item from the right panel list
                    draggable.classList.remove('faded');
                    draggable.classList.add('removed');

                    // ... and then delete it from the DOM
                    draggable.addEventListener("transitionend", function ()
                    {
                        draggable.remove()
                    });

                    // we only want to add the transaction to the page if the dragged item belongs to this year
                    if (pageYear === itemYear) {
                        let targetCell = e.target.closest('tr').querySelector('[data-month="' + itemMonth + '"] > div');
                        let link = document.createElement('a');
                        link.setAttribute('href', "");
                        if(amountValue > 0) {
                            link.classList.add('deficit');
                        }
                        link.textContent = draggable.querySelector('span.amount').innerHTML;
                        targetCell.appendChild(link);
                    }
                }
            });
        }
}

export default cmpDragNDrop;
