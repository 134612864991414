import utils from "../common/util";
import modal from "../components/cmp-confirm-delete-modal"

const cmpDeleteRow = {
    component: null,

    init: function (element)
    {
        utils.log('cmpDeleteRow initiated : #' + element.id, 'init');

        const _ = this;
        _.component = element;

        _.onClickEvent(element);
    },

    onClickEvent: function (element)
    {
        element.addEventListener('click', (e) =>
        {
            const _ = this;
            _.label = e.target.closest('button').getAttribute('data-row-label');
            _.dbTable = e.target.closest('button').getAttribute('data-row-table');
            _.rowId = e.target.closest('button').getAttribute('data-row-id');
            _.row = e.target.closest('tr');

            modal.setRow(_.row)
            modal.showModal(_.label || null);
        });
    },

    /**
     * Deletes the table row from the page. This action does not affect the database
     */
    deleteRowFromUI()
    {
        const _ = this;
        let pillBtn = _.row.querySelector('.pill-btn.small-btn');

        _.row.setAttribute('data-status', 'Deleted');
        pillBtn.classList.remove('success', 'warning');
        pillBtn.classList.add('danger');
        pillBtn.innerText = 'Deleted';
    },

    /**
     * Deletes the table row from the page. This action does not affect the database
     */
    deleteRowFromDB()
    {
        const _ = this;
        fetch(utils.getAppUrl() + '/' + _.dbTable + '/' + _.rowId, {
            method: 'DELETE',
            headers: {'X-CSRF-TOKEN': utils.getCsrfToken()},
        })
            .then(response => response.json())
        // .then((data) =>
        // {
        //console.log(data.status);
        // })
    }
}

export default cmpDeleteRow;
