import utils from "../common/util";

const cmpFilterTables = {
    component: null,

    init: function (element)
    {
        utils.log('cmpFilterCompanies initiated : #' + element.id, 'init');

        const _ = this;
        _.component = element;
        _.filterTable = document.getElementById(element.getAttribute('data-table'));


        _.onClickEvent(element);
    },

    onClickEvent: function (element)
    {
        const _ = this;

        element.addEventListener('click', (e) =>
        {
            let btn = (e.target);
            let filter = btn.innerText;

            if (btn.classList.contains('disabled')) {
                console.log('inactive');
                _.filterTable.classList.remove(filter.toLowerCase());
            } else {
                console.log('not inactive');
                _.filterTable.classList.add(filter.toLowerCase());
            }
            /**
             * For each row, first we add the 'hidden' class so we can get minimal animation. Then we add the
             * 'removed' class to physically remove the row - allowing the overtall table height to be correct
             */
            // filterRows.forEach( (row) => {
            //     row.classList.toggle('hidden');
            //     setTimeout(() => {
            //         row.classList.toggle('removed')
            //     }, 300)
            // })
        });
    }
}

export default cmpFilterTables;
