import utils from "../common/util";

const cmpFlash = {
    component: null,

    init: function (element)
    {
        utils.log('cmpFlash initiated : #' + element.id, 'init');

        const _ = this;
        _.component = element;

        _.removeFlash();
    },

    removeFlash: function ()
    {
        const _ = this;

        let timer = 5000;
        if(_.component.classList.contains('alert-success')){
            timer = 4000;
        }
        if(_.component.classList.contains('alert-danger')){
            timer = 8000;
        }


        setTimeout(() => {
            _.component.classList.add('hidden');
        }, timer);

    }
}

export default cmpFlash;
