import utils from "../common/util";

const cmpToggleButton = {
    component: null,

    init: function (element)
    {
        utils.log('cmpToggleButton initiated : #' + element.id, 'init');

        const _ = this;
        _.component = element;
        _.startClass = _.component.getAttribute('data-start-class');
        _.toggleClass = _.component.getAttribute('data-toggle-class');

        _.onClickEvent(element);
    },

    onClickEvent: function (element)
    {
        const _ = this;

        element.addEventListener('click', (e) =>
        {
            let btn = (e.target);
            btn.classList.toggle(_.startClass);
            btn.classList.toggle(_.toggleClass);
        });
    }
}

export default cmpToggleButton;
