import cmpToggleButton from "./components/cmp-toggle-button";
import cmpLinkButton from "./components/cmp-link-button";
import cmpConfirmDeleteModal from "./components/cmp-confirm-delete-modal";
import cmpRightPanel from "./components/cmp-right-panel";
import cmpDragNDrop from "./components/cmp-drag-n-drop";
import cmpFlash from "./components/cmp-flash";
import cmpFilterTables from "./functions/cmp-filter-tables";
import cmpDeleteRow from "./functions/cmp-delete-row";

export const componentScripts = {
    cmpToggleButton: cmpToggleButton,
    cmpLinkButton: cmpLinkButton,
    cmpRightPanel: cmpRightPanel,
    cmpDragNDrop: cmpDragNDrop,
    cmpFlash: cmpFlash,
    cmpConfirmDeleteModal: cmpConfirmDeleteModal,
    cmpFilterTables: cmpFilterTables,
    cmpDeleteRow: cmpDeleteRow,
}

/**
 * after document loads, loop over all [data-component]s on the page to initiate their script
 */

document.addEventListener('DOMContentLoaded', () =>
{
    // find all elements with component attribute
    const componentElements = document.querySelectorAll('[data-component]');
    const componentFunctions = document.querySelectorAll('[data-function]');

    // iterate over all component elements
    for (const el of componentElements) {
        let componentName = el.getAttribute('data-component');

        // get the script that matches the component
        let componentScript = componentScripts[componentName];

        // if the script exists, fire the init
        if (typeof componentScript != 'undefined') {
            componentScript.init(el);
        }
    }

    // iterate over all component functions
    for (const el of componentFunctions) {
        let componentFunction = el.getAttribute('data-function');

        // get the script that matches the component
        let componentScript = componentScripts[componentFunction];

        // if the script exists, fire the init
        if (typeof componentScript != 'undefined') {
            componentScript.init(el);
        }
    }
});
