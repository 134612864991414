import utils from "../common/util";
import deleteRowFunction from "../functions/cmp-delete-row";

const cmpConfirmDeleteModal = {
    component: null,

    /**
     * Initialise the modal
     * @param element
     */
    init: function (element)
    {
        utils.log('cmpConfirmModal initiated : #' + element.id, 'init');

        const _ = this;
        _.component = element;
        _.warningText = _.component.querySelector('#confirm-modal-warning-text')
        _.cancelButton = _.component.querySelector('#confirm-modal-cancel-btn');
        _.continueButton = _.component.querySelector('#confirm-modal-continue-btn');
        _.deleteItemLabel = _.warningText.querySelector('.row-label');

        _.onClickEvent(element);
    },

    /**
     * Perform actions when elements of the modal are clicked
     * @param element
     */
    onClickEvent: function (element)
    {
        const _ = this;

        element.addEventListener('click', (e) =>
        {
            if (e.target === _.cancelButton) {
                _.hideModal();
            }

            if (e.target === _.continueButton) {
                deleteRowFunction.deleteRowFromUI();
                deleteRowFunction.deleteRowFromDB();
                _.hideModal();
            }
        });
    },

    /**
     * Show the modal and backwash, and lock the body so it cannot be scrolled
     * @param label
     */
    showModal: function (label = null)
    {
        const _ = this;

        if (label) {
            _.addLabelToText(label);
        }

        _.component.classList.add('active');
    },

    /**
     * Hide the modal and backwash and reset text labels
     */
    hideModal: function ()
    {
        const _ = this;

        _.component.classList.remove('active');
        _.deleteItemLabel.classList.remove('active');
        _.deleteItemLabel.innerText = 'this item';
    },

    /**
     * In the modal's warning message, replace the generic 'this item' text with the label of the row being deleted
     * @param label
     */
    addLabelToText: function (label)
    {
        const _ = this;

        _.deleteItemLabel.classList.add('active');
        _.deleteItemLabel.innerText = label;
    },

    /**
     * Set the row to be deleted.
     * @param row
     */
    setRow(row)
    {
        const _ = this;

        _.row = row;
    }
}

export default cmpConfirmDeleteModal;
